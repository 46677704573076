<template>
  <div>
    <div class="vx-row ml-1 mt-3">
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Warehouse</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <Warehouse
            :value="this.selectedWarehouse"
            @selected="(val) => { this.selectedWarehouse = val }"
          ></Warehouse>
        </div>
      </div>
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Document Date</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <Date
            :default="false"
            @selected="(val) => { this.documentDate = val }"
          ></Date>
        </div>
      </div>
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
        </div>
      </div>
    </div>

    <div class="vx-row mt-3">
      <div class="vx-col w-full mb-base">
        <Table
          :draw="draw"
          :selectedWarehouse="selectedWarehouse"
          :documentDate="documentDate"
          :status="status"
          :tab="activeTab"
        ></Table>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "../component/Table.vue";
import MssID from "../component/MssID.vue"
import Warehouse from "../component/Warehouse.vue"
import Date from "../component/DateV2.vue";

export default {
  components: {
    Date,
    Table,
    Warehouse,
    MssID,
  },
  data() {
    return {
      draw: 0,
      status: 3,
      selectedWarehouse: null,
      documentDate: null,
      activeTab: "ClosedMSS",
    };
  },
  methods: {

    handleFilter() {
      this.draw++;
    },
  },
}
</script>
