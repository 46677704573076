<template>
  <vx-card :title="title">
    <vs-tabs :value="activeTab">
      <vs-tab label="Draft">
        <NewTab></NewTab>
      </vs-tab>
      <vs-tab label="Waitting">
        <WaitingTab></WaitingTab>
      </vs-tab>
      <vs-tab label="Approved">
        <ApprovedTab></ApprovedTab>
      </vs-tab>
      <vs-tab label="Closed">
        <ClosedTab></ClosedTab>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import NewTab from "./_tab/NewTab.vue";
import WaitingTab from "./_tab/WaittingTab.vue";
import ApprovedTab from "./_tab/ApprovedTab.vue";
import ClosedTab from "./_tab/ClosedTab.vue";

export default {
  components: {
    NewTab,
    WaitingTab,
    ApprovedTab,
    ClosedTab,
  },
  data() {
    return {
      title: "Missing Stock Settlement",
      activeTab : this.$route.query.tab ? this.$route.query.tab : 0
    }
  }
}
</script>
